import $ from "jquery";

const MAIN_CONTAINER_PADDING = 60;
const OFFSET_TO_FOOTER = 120;

export default class Sidebar {
  constructor($el) {
    this.$el = $el;
    this.$doc = $(document);
    this.$sidebar = this.$el.find('.sidebar');
    this.$footer = this.$doc.find('.footer');
    this.mainHeight = this.$doc.height() - this.$footer.innerHeight();
    this.sidebarTopOffset = this.$doc.find('.member-nav').length ?
      this.$doc.find('.lead').innerHeight() + this.$doc.find('.header').innerHeight() + this.$doc.find('.member-nav').outerHeight(true) :
      this.$doc.find('.lead').innerHeight() + this.$doc.find('.header').innerHeight();
    this.events();
    this.setLeftOffset();
    this.setPos();
  }

  events() {
    $(window).on('scroll', this.onScroll);
    $(window).on('resize', this.onResize);
  }

  onScroll = () => {
    this.setPos();
  }

  onResize = () => {
    this.setLeftOffset();
    this.setPos();
  }

  setLeftOffset = () => {
    this.sidebarTipLeftOffset = this.$doc.find('.container').offset().left < MAIN_CONTAINER_PADDING ? MAIN_CONTAINER_PADDING : 0;
    this.sidebarDefaultLeftOffset = this.$doc.find('.container').offset().left < MAIN_CONTAINER_PADDING ? MAIN_CONTAINER_PADDING : this.$doc.find('.container').offset().left;
  }

  setPos = () => {
    const scrollPosition = $(window).scrollTop();
    const distToFooter = this.mainHeight - scrollPosition;
    if ($(window).height() > this.sidebarTopOffset + this.$sidebar.height()) {
      if (this.$sidebar.height() + this.sidebarTopOffset + OFFSET_TO_FOOTER < distToFooter) {
        this.$sidebar.addClass('fix');
        this.$sidebar.css({
          "top": this.sidebarTopOffset,
          "left": this.sidebarDefaultLeftOffset
        });
      } else {
        this.$sidebar.removeClass('fix');
        this.$sidebar.css({
          "top": `${this.mainHeight - this.sidebarTopOffset - this.$sidebar.height() - OFFSET_TO_FOOTER}px`,
          "left": this.sidebarTipLeftOffset
        });
      }
    } else {
      this.$sidebar.removeClass('fix');
      this.$sidebar.css({
        "left": this.sidebarTipLeftOffset
      });
    }
  }
}
