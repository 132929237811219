import $ from 'jquery';

export default class SelectBox {
  constructor($el) {
    this.$el = $el;
    this.selector = this.$el.attr('class').split(' ').map((s) => ('.' + s)).join('');
    this.$pullDownBox = this.$el.find('.pull-down-box');
    this.events();
  }

  events() {
    this.$el.on('click', '.top-box', this.onClickTopBox);
    this.$el.on('click', '.pull-down-item', this.onClickItem);
    $(document).on('click', (e) => {
      if (!$(e.target).closest(this.selector).length) {
        this.onClickOutside();
      }
    });
  }

  onClickTopBox = () => {
    this.$pullDownBox.toggleClass('open');
  }

  onClickOutside() {
    this.close();
  }

  onClickItem = (e) => {
    const href = e.target.dataset.href;
    if (location.pathname !== href) {
      location.href = href;
    } else {
      this.close();
    }
  }

  close() {
    this.$pullDownBox.removeClass('open');
  }
}
