import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";

export default class AutoComplete {
  constructor() {
    this.setAutoComplete();
  }

  setAutoComplete = () => {
    $('#presentation_member_name').autocomplete({
      source: function( request, response ) {
        $.ajax({
          url: "/ja/admin/presentation_members",
          dataType: "json",
          data: {
            term: request.term
          },
        })
          .done(data => {
            response(data);
          });
      },
      select: function( event, ui ) {
        $('#presentation_member_name').val(ui.item.label);
        $('#presentation_member_wantedly_uid').val(ui.item.id);
      },
      delay: 300,
      minLength: 2,
    });
  }
}
