import $ from "jquery";
import _ from 'lodash';
const SLIDESHOW_INTERVAL = 4000;  // ms
const TRANSITION_DURATION = 700;
const OFFSET_Y = 10;  // px
const FAST_TRANSITION_DURATION = TRANSITION_DURATION * 0.4;
const DEFAULT_EASING = 'ease-in-out';
const FAST_DURATION_EASING = 'linear';
const IMAGE_OFFSET = 50;

export default class CarouselManager {
  constructor($el) {
    this.$el = $el;
    this.indicators = this.$el.find('.page-index');
    this.currentIndex = 0;
    this.relocateImages();
    this.run();
    this.events();
    this.swiped = false;
    this.indicators.eq(0).addClass('show');
  }

  relocateImages = () => {
    this.images = this.$el.find('img');
    this.offset = (this.images.eq(this.currentIndex).width()) + IMAGE_OFFSET;
    this.images.eq(this.currentIndex).addClass('show');
    this.surfaceImage(this.currentIndex);
    for (let i = 0; i < this.images.length; i++) {
      this.moveImage(i, this.offset * 2, 0, '');
    }
    this.moveImage(this.backwardIndex(this.currentIndex), -this.offset, 0, '');
    this.moveImage(this.currentIndex, 0, 0, '');
    this.moveImage(this.forwardIndex(this.currentIndex), this.offset, 0, '');
  }

  run = () => {
    this.runner = setInterval(() => {
      this.switchImage(false, false);
    }, SLIDESHOW_INTERVAL);
  }

  events = () => {
    this.indicators.on("click", this.onClick);
    this.images.on("touchstart", this.onTouchStart);
    this.images.on("touchmove", this.onSwipe);
    this.images.on("touchend", this.onTouchEnd);
    $(window).on('resize', _.throttle(this.onResize, 100));
  }

  switchImage = (fast, back) => {
    const duration = fast ? FAST_TRANSITION_DURATION : TRANSITION_DURATION;
    const easing = fast ? FAST_DURATION_EASING : DEFAULT_EASING;
    this.currentIndex = this.nextIndex(this.currentIndex, back);
    this.moveImages(duration, easing, back);
    const prevIndex = this.prevIndex(this.currentIndex, back);
    const nextIndex = this.nextIndex(this.currentIndex, back);

    this.images.eq(this.currentIndex).addClass('show');
    this.images.eq(prevIndex).removeClass('show');
    this.images.eq(nextIndex).removeClass('show');

    this.indicators.eq(prevIndex).removeClass('show');
    this.indicators.eq(nextIndex).removeClass('show');
    this.indicators.eq(this.currentIndex).addClass('show');
  }

  moveImages = (duration, easing, back) => {
    const prevIndex = this.prevIndex(this.currentIndex, back);
    const beforePrevIndex = this.prevIndex(prevIndex, back);
    const headIndex = this.prevIndex(beforePrevIndex, back);
    const nextIndex = this.nextIndex(this.currentIndex, back);
    const dir = back ? -1 : 1;

    this.surfaceImage(this.currentIndex, duration);
    this.moveImage(headIndex, this.offset * 2 * dir, 0, easing);
    this.moveImage(beforePrevIndex, -this.offset * 2 * dir, duration, easing);
    this.moveImage(prevIndex, -this.offset * dir, duration, easing);
    this.moveImage(nextIndex, this.offset * dir, duration, easing);
  }

  moveImage = (idx, offset, duration, easing) => {
    this.images.eq(idx).css({
      transition: `all ${duration}ms ${easing}`,
      transform: `translateX(${offset}px)`
    });
  }

  surfaceImage = (idx, duration) => {
    this.images.eq(idx).css({
      transition: `all ${duration}ms ease-in-out`,
      transform: `translate(0px, -${OFFSET_Y}px)`
    });
  }

  forwardIndex = (idx) => {
    return idx === this.images.length - 1 ? 0 : idx += 1;
  }

  backwardIndex = (idx) => {
    return idx === 0 ? this.images.length - 1 : idx - 1;
  }

  prevIndex = (idx, back) => {
    return back ? this.forwardIndex(idx) : this.backwardIndex(idx);
  }

  nextIndex = (idx, back) => {
    return back ? this.backwardIndex(idx) : this.forwardIndex(idx);
  }

  switchImageAndSetTimeout = (count, maxCount, back, callback) => {
    this.switchImage(true, back);
    if (count === maxCount) {
      callback();
    } else {
      setTimeout (() => {
        this.switchImageAndSetTimeout(count + 1, maxCount, back, callback);
      }, FAST_TRANSITION_DURATION);
    }
  }

  onClick = (e) => {
    clearInterval(this.runner);
    let dist = this.indicators.index(e.target) - this.currentIndex;
    if(dist !==0) {
      let back = false;
      const threshold = this.images.length / 2.0;
      if (dist < -threshold) {
        dist = this.images.length + dist;
      } else if (dist < 0) {
        dist *= -1;
        back = true;
      } else if (dist > threshold) {
        dist = this.images.length - dist;
        back = true;
      }
      this.switchImageAndSetTimeout(1, dist, back, this.run);
    } else {
      this.run();
    }
  }

  onResize = () => {
    clearInterval(this.runner);
    this.relocateImages();
    this.run();
  }

  onTouchStart = (e) => {
    this.touchPosition = this.getPosition(e);
    clearInterval(this.runner);
  }

  onSwipe = (e) => {
    if (!this.swiped) {
      const dist = this.touchPosition - this.getPosition(e);
      let back;
      if (Math.abs(dist) > 70) {
        if (Math.sign(dist) === 1) {
          back = false;
        } else {
          back = true;
        }
        this.switchImage(true, back);
        this.swiped = true;
      }
    }
  }

  onTouchEnd = () => {
    this.swiped = false;
    this.run();
  }

  getPosition = (e) => {
    return e.originalEvent.touches[0].pageX;
  }
}
