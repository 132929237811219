import $ from "jquery";
import sum from "lodash/sum";

export default class MobileSubHeader {
  constructor($el) {
    this.$el = $el;
    this.$scroll = $el.find("ul");
    this.$navPrev = $el.find(".sidebar-nav.prev");
    this.$navNext = $el.find(".sidebar-nav.next");
    this.items = $el.find("li > a").toArray();
    this.headIndex = 0;
    this.tailIndex = 0;

    this.tryToDisableNav();
    this.events();
  }

  events() {
    this.$scroll.on("scroll", this.onScroll);
    this.$navPrev.on("click", this.scrollTo.bind(this, false));
    this.$navNext.on("click", this.scrollTo.bind(this, true));
    $(window).on("scroll", this.onScrollWindow);
  }

  tryToDisableNav() {
    const containerWidth = this.$scroll.width();
    const itemsWidth = sum(this.items.map(item => item.clientWidth));
    if (containerWidth > itemsWidth) {
      this.$navPrev.addClass("disabled");
      this.$navNext.addClass("disabled");
    }
  }

  onScroll = () => {
    const scroll = this.$scroll.scrollLeft();
    const width = this.$scroll.get(0).scrollWidth;
    const winWidth = window.outerWidth;
    this.$navPrev.toggleClass("disabled", scroll === 0);
    this.$navNext.toggleClass("disabled", scroll === width - winWidth);
  };

  onScrollWindow = () => {
    const $win = $(window);
    const scroll = $win.scrollTop();
    const headerTop = 52;
    if (scroll > headerTop) {
      this.$el.addClass("sticky");
    } else {
      this.$el.removeClass("sticky");
    }
  };

  scrollTo = (next = true) => {
    const headIndex = this.calcHeadIndex();
    const target = this.items[headIndex + (next ? 1 : -1)];
    if (!target) {
      return;
    }
    const scroll = target.offsetLeft;
    const offset = this.items[0].offsetLeft;
    this.$scroll.animate({
      scrollLeft: scroll - offset
    });
  };

  currentItem() {
    return this.items[this.currentIndex];
  }

  calcHeadIndex() {
    const headRect = this.$navPrev.get(0).getBoundingClientRect();
    const head = document.elementFromPoint(headRect.right + 1, headRect.top + 1);
    const index = this.items.indexOf(head);
    return index < 0 ? this.headIndex : index;
  }

  calcTailIndex() {
    const headRect = this.$navNext.get(0).getBoundingClientRect();
    const head = document.elementFromPoint(headRect.left - 1, headRect.top + 1);
    const index = this.items.indexOf(head);
    return index < 0 ? this.tailIndex : index;
  }
}
