import $ from "jquery";
import debounce from "lodash/debounce";
import { MEDIA_QUERY_MOBILE_TABLET } from "./constants";

export default class Footer {
  constructor($el) {
    this.$el = $el;
    this.$sections = this.$el.find("section");
    this.events();
    this.onResize();
  }

  events() {
    window.addEventListener("resize", this.onResizeDebounced);
  }

  onResize = () => {
    const isMobile = this.matchIsMobile();
    if (isMobile === this.isMobile) {
      return;
    }
    this.isMobile = isMobile;
    if (this.sections) {
      this.sections.each((_, sec) => sec.destroy());
    }
    this.sections = this.$sections.map((_, el) => new FooterSection($(el), isMobile));
  };

  onResizeDebounced = debounce(this.onResize, 300);

  matchIsMobile() {
    return window.matchMedia(MEDIA_QUERY_MOBILE_TABLET).matches;
  }
}

class FooterSection {
  constructor($el, isMobile) {
    this.$el = $el;
    this.isMobile = isMobile;
    this.$title = this.$el.find(".section-title");
    this.$list = this.$el.find("ul");
    this.$items = this.$el.find("li > a");

    this.$title.attr("tabindex", this.isMobile ? "0" : "");
    this.toggle(!this.isMobile);

    this.events();
  }

  events() {
    if (this.isMobile) {
      this.$title.on("click", this.onClick);
    }
  }

  destroy() {
    this.$title.off("click", this.onClick);
  }

  onClick = () => {
    this.toggle(!this.isExpanded());
  };

  isExpanded() {
    return this.$list.attr("aria-expanded") === "true";
  }

  toggle(expand = true) {
    if (expand) {
      this.$list.css("height", "auto");
      const height = this.$list.height();
      this.$list.css("height", "0");
      this.$list.height(`${height}px`);

      this.$list.attr("aria-expanded", "true");
      this.$items.attr("tabindex", "0");
      this.$el.addClass("is-expanded");
    } else {
      this.$list.css("height", "0px");
      this.$list.attr("aria-expanded", "false");
      this.$items.attr("tabindex", "-1");
      this.$el.removeClass("is-expanded");
    }
  }
}
