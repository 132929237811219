import $ from 'jquery';
import _ from 'lodash';

export default class MemberNav {
  constructor($el) {
    this.$el = $el;
    this.$itemsContainer = this.$el.find('.member-nav-items-container');
    this.$itemsWindow = this.$el.find('.member-nav-window');
    this.initItems();
    this.calcItemSize();
    this.events();
  }

  initItems() {
    this.items = [];
    this.$el.find('.member-nav-item').each((index, item) => {
      this.items.push(new MemberItem($(item)));
    });
    this.currentItem = this.items[0];
    this.currentItem.setDefault();
  }

  calcItemSize() {
    const $item = this.$el.find('.member-nav-item');
    this.itemWidth = $item.width() + parseInt($item.css('marginRight'), 10);
  }

  events() {
    $(window).on('resize', _.throttle(this.onWindowResize, 100));
    this.$el.on('click', '.member-nav-item', this.onClickItem);
    this.$el.on('click', '.nav-right', this.onClickNavRight);
    this.$el.on('click', '.nav-left', this.onClickNavLeft);
  }

  onWindowResize = () => {
    if ($(window).width() < 1280) {
      this.$el.addClass('narrow');
    } else {
      this.$el.removeClass('narrow');
    }
  }

  onClickItem = (e) => {
    this.currentItem.unselect();
    const newIndex = $(e.target).closest('.member-nav-item').data('memberId');
    this.currentItem = this.items[newIndex];
    this.currentItem.select();
  }

  onClickNavRight = () => {
    const slideOffset = this.calcSlideOffset();
    this.$itemsContainer.css({
      transform: `translate3d(-${slideOffset}px, 0, 0)`
    });
  }

  onClickNavLeft = () => {
    this.$itemsContainer.css({
      transform: `translate3d(0, 0, 0)`
    });
  }

  calcSlideOffset() {
    const visibleItemsCount = parseInt(this.$itemsWindow.width() / this.itemWidth, 10) + 1;
    return this.itemWidth * (this.items.length - visibleItemsCount);
  }
}

class MemberItem {
  constructor($el) {
    this.$el = $el;
    this.$avatarWrapper = this.$el.find('.avatar-wrapper');
    this.memberId = this.$el.data('member-id');
    this.$mainItem = $(`#member-${this.memberId}`);
  }

  setDefault = () => {
    this.$avatarWrapper.addClass('selected');
    this.$mainItem.addClass('show');
  }

  select = () => {
    this.$avatarWrapper.addClass('selected');
    setTimeout(() => {
      this.$mainItem.fadeIn(300);
    },
    200);
  }

  unselect = () => {
    this.$avatarWrapper.removeClass('selected');
    this.$mainItem.fadeOut(100);
  }
}
