import $ from "jquery";

export default class AwardPcHeader {
  constructor($el, $targetEl) {
    this.$header = $el;
    this.$window = $(window);
    this.open = false;
    this.scroll = 0;
    this.links = $(".header").find('.item');
    this.sections = $targetEl.find('section');
    this.events();
  }

  events() {
    this.$window.on("scroll", this.onScroll);
    this.links.on("click", this.onClick);
  }

  onScroll = () => {
    var px_change = 90;

    if (this.$window.scrollTop() > px_change) {
      $('.header').addClass("scroll");

    } else if ($('.header').hasClass("scroll")) {
      $('.header').removeClass("scroll");
    }
  };

  onClick = (e) => {
    const offset = 0;
    const idx = this.links.index(e.currentTarget) + 1;
    $("html,body").animate({scrollTop:$(this.sections[idx]).offset().top-offset});
  }
}
