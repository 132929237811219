import $ from "jquery";

const DELTA = -0.125;

export default class ParallaxManager {
  constructor() {
    this.ticking = false;
    this.items = [];
    this.$window = $(window);
    this.initItems();
    this.events();
  }

  initItems() {
    $('.move-fast, .move-slow').each((i, item) => {
      this.items.push(new ParallaxItem($(item)));
    });
  }

  events() {
    this.$window.on('scroll', this.onScroll);
  }

  onScroll = () => {
    this.requestTick(this.animate);
  }

  requestTick = (callback) => {
    if (this.ticking) {
      return;
    }
    this.ticking = true;
    window.requestAnimationFrame(callback);
  }

  animate = () => {
    this.ticking = false;
    const scroll = this.$window.scrollTop();
    this.items.forEach((item) => {
      item.animate(scroll);
    });
  }
}

class ParallaxItem {
  constructor($el) {
    this.$el = $el;
    this.modeSlow = this.$el.hasClass('move-slow');
    this.setInitPos();
  }

  setInitPos() {
    const scrollTop = $(window).scrollTop();
    const windowHeight = $(window).height();
    const elHeight = this.$el.height();
    const elTop = this.$el.offset().top;
    this.scrollOffset = elTop + elHeight / 2 - windowHeight / 2;

    // For elements in the initial window view, the following block ensures an initial value of `translateOffset`
    // being calculated to 0 in `animate()` function, as `translateOffset` is prppotional to
    // `$(window).scrollTop() - this.scrollOffset`.
    if (scrollTop - elHeight <= elTop && elTop <= scrollTop + windowHeight) {
      this.scrollOffset = scrollTop;
    }

    this.animate(scrollTop);
  }

  animate = (scroll) => {
    const moveHeight = (scroll - this.scrollOffset) * DELTA;
    this.translateOffset = this.modeSlow ? moveHeight : -moveHeight;
    this.$el.css(
      {
        transform: `translate3d(0, ${this.translateOffset}px, 0)`
      }
    );
  }
}
