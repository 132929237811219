import $ from "jquery";

export default class FaqItem {
  constructor($item){
    this.$el = $item;
    this.$answerBlock = this.$el.find(".answer-block");
    this.open = false;
    this.events();
  }

  events() {
    this.$el.on("click", this.onClick);
  }

  onClick = (e) => {
    if ($(e.target).closest('.answer-block').length) {
      return;
    }
    this.open = !this.open;
    if (this.open) {
      this.$el.addClass("open");
      this.$answerBlock.css("height", "auto");
      const height = this.$answerBlock.height();
      this.$answerBlock.css("height", "0");
      this.$answerBlock.height(`${height}px`);
    } else {
      this.$el.removeClass("open");
      this.$answerBlock.height("0px");
    }
  }
}
