/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "../styles";
import $ from "jquery";
import "picturefill";
import Rails from "rails-ujs";
import { VIEWPORT_BOUNDARY_MIDDLE } from "../constants";
import ParallaxManager from "../parallax";
import SlideshowManager from "../slideshow";
import CopyButton from "../copy_button";
import initMap from "../map";
import MemberNav from "../member_nav";
import MobileMemberNav from "../mobile_member_nav";
import SelectBox from "../select_box";
import FaqItem from "../faq";
import MobileHeader from "../mobile_header";
import AwardPcHeader from "../award_pc_header";
import MobileSubHeader from "../mobile_sub_header";
import Sidebar from "../sidebar";
import Footer from "../footer";
import CarouselManager from "../carousel";
import AutoComplete from "../auto_complete";

Rails.start();

const windowWidth = $(window).width();

$(document).ready(function() {
  // mobile and tablet
  if (!document.getElementById("awards-2019")) {
    if (windowWidth <= VIEWPORT_BOUNDARY_MIDDLE) {
      new MobileHeader($(".header"));
      new MobileSubHeader($(".sidebar-wrapper"));
    } else {
      $(".sidebar-wrapper").each((i, item) => {
        new Sidebar($(item));
      });
    }
    new Footer($(".footer"));
  }

  if (document.getElementById("home-index")) {
    new ParallaxManager();
    new SlideshowManager($("#home-section-top .image-block"));
    initMap();
    new CopyButton(".copy-btn");
  } else if (document.getElementById("products-index")) {
    new ParallaxManager();
  } else if (document.getElementById("company-leadership")) {
    if (windowWidth > VIEWPORT_BOUNDARY_MIDDLE) {
      new MemberNav($(".member-nav"));
    } else {
      new MobileMemberNav($(".member-nav"));
    }
  } else if (
    document.getElementById("news-index") ||
    document.getElementById("ir-news") ||
    document.getElementById("ir-library") ||
    document.getElementById("careers-presentations")
  ) {
    $(".select-box").each((i, item) => {
      new SelectBox($(item));
    });
  } else if (document.getElementById("ir-faq")) {
    $(".item").each((i, item) => {
      new FaqItem($(item));
    });
  } else if (document.getElementById("ir-financials")) {
    //
  } else if (document.getElementById("careers-benefits")) {
    new CarouselManager($(".image-block"));
  } else if (document.getElementById("awards-2019")) {
    initMap();
    new CopyButton(".copy-btn");
    if (windowWidth > VIEWPORT_BOUNDARY_MIDDLE) {
      new AwardPcHeader($(".header"), $('#campaigns-awards'));
    }
  } else if (document.getElementById("careers-jobs")) {
    new SlideshowManager($(".image-block"));

  } else if (
    document.getElementById("admin-presentations-new") ||
    document.getElementById("admin-presentations-edit")
  ) {
    new AutoComplete();
  }
});
