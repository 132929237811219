import $ from "jquery";
import Clipboard from "clipboard";

export default class CopyButton {
  constructor(selector) {
    this.$el = $(selector);
    this.textBefore = this.$el.data("tooltip-text");
    this.textAfter = this.$el.data("tooltip-text-after");
    this.clicked = false;
    new Clipboard(selector);
    this.events();
  }

  events() {
    this.$el.on("click", this.onClick);
    this.$el.on("mouseleave", this.onMouseleave);
  }

  onClick = () => {
    this.$el.attr("data-tooltip-text", this.textAfter);
    this.clicked = true;
    this.$el.addClass("copied");
    setTimeout(() => {
      this.$el.removeClass("copied");
    }, 1500);
  };

  onMouseleave = () => {
    if (this.clicked) {
      setTimeout(() => {
        this.$el.attr("data-tooltip-text", this.textBefore);
      }, 200);
    }
    this.clicked = false;
  };
}
