import $ from 'jquery';
import debounce from "lodash/debounce";

export default class MobileMemberNav {
  constructor($el) {
    this.$el = $el;
    this.$itemsContainer = this.$el.find('.member-nav-items-container');
    this.$itemsWindow = this.$el.find('.member-nav-window');
    this.initItems();
    this.calcItemSize();
    this.initDots();
    this.events();
  }

  initItems() {
    this.items = [];
    this.$el.find('.member-nav-item').each((index, item) => {
      this.items.push(new MemberItem($(item)));
    });
    this.currentItem = this.items[0];
    this.currentItem.setDefault();
  }

  calcItemSize() {
    const $item = this.$el.find('.member-nav-item');
    this.itemWidth = $item.width() + parseInt($item.css('marginRight'), 10);
  }

  initDots() {
    this.$navDots = this.$el.find('.nav-dots');
    const windowWidth = $(window).width();
    this.itemNumPerPage = Math.floor(windowWidth / this.itemWidth);
    this.pageMax = Math.ceil(this.items.length / this.itemNumPerPage);
    this.currentPage = 0;

    for(let page = 0; page < this.pageMax; page++) {
      const $dotEl = $('<div>').addClass('nav-dot');
      const $dotInnerEl = $('<div>').addClass('nav-dot-inner');
      if (page == 0) {
        $dotInnerEl.addClass('selected');
      }
      $dotEl.append($dotInnerEl);
      $dotEl.data('page', page);
      $dotEl.on('touchend', this.onTouchNavDot);
      this.$navDots.append($dotEl);
    }
  }

  events() {
    this.$itemsWindow.on('scroll', debounce(this.onScroll, 10));
    this.$el.on('touchend', '.member-nav-item', this.onTouchItem);
  }

  onScroll = () => {
    const slideOffset = this.$itemsWindow.scrollLeft();
    const page = Math.round(slideOffset / (this.itemWidth * this.itemNumPerPage));
    this.selectPage(page, false);
  }

  onTouchItem = (e) => {
    this.currentItem.unselect();
    const newIndex = $(e.target).closest('.member-nav-item').data('memberId');
    this.currentItem = this.items[newIndex];
    this.currentItem.select();
  }

  onTouchNavDot = (e) => {
    const page = $(e.target).data().page;
    this.selectPage(page, true);
  }

  selectPage(page, slide) {
    if (this.currentPage == page) {
      return;
    }
    this.$navDots.find('.nav-dot-inner').eq(this.currentPage).removeClass('selected');
    this.$navDots.find('.nav-dot-inner').eq(page).addClass('selected');
    if (slide) {
      const slideOffset = this.itemWidth * this.itemNumPerPage * page;
      this.$itemsWindow.animate({ scrollLeft: slideOffset }, 250);
    }
    this.currentPage = page;
  }
}

class MemberItem {
  constructor($el) {
    this.$el = $el;
    this.$avatarWrapper = this.$el.find('.avatar-wrapper');
    this.memberId = this.$el.data('member-id');
    this.$mainItem = $(`#member-${this.memberId}`);
  }

  setDefault = () => {
    this.$avatarWrapper.addClass('selected');
    this.$mainItem.addClass('show');
  }

  select = () => {
    this.$avatarWrapper.addClass('selected');
    setTimeout(() => {
      this.$mainItem.fadeIn(300);
    },
    200);
  }

  unselect = () => {
    this.$avatarWrapper.removeClass('selected');
    this.$mainItem.fadeOut(100);
  }
}
