const SLIDESHOW_INTERVAL = 4000;  // ms

export default class SlideshowManager {
  constructor($el) {
    this.$el = $el;
    this.images = this.$el.find('img');
    this.currentIndex = 0;
    this.run();
  }

  run = () => {
    setInterval(
      this.switchImage,
      SLIDESHOW_INTERVAL
    );
  }

  switchImage = () => {
    this.images.eq(this.currentIndex).removeClass('show');
    if (this.currentIndex === this.images.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }
    this.images.eq(this.currentIndex).addClass('show');
  }

}
