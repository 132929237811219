import $ from "jquery";
import _ from "lodash";

export default class MobileHeader {
  constructor($el) {
    this.$el = $el;
    this.$navMenu = $(".mobile-nav");
    this.$main = $(".main");
    this.$footer = $("footer");
    this.$window = $(window);
    this.open = false;
    this.scroll = 0;
    this.sticky = false;
    this.visible = false;
    this.events();
  }

  events() {
    this.$el.on("click", ".menu-icon", this.toggleMenuOpen);
    this.$window.on("scroll", _.throttle(this.onScroll));
  }

  toggleMenuOpen = e => {
    e.preventDefault();
    this.open = !this.open;
    this.$el.toggleClass("open");
    this.$navMenu.toggleClass("open");
    this.$main.toggleClass("nav-open");
    this.$footer.toggleClass("nav-open");
    if (this.open) {
      $("html, body").css({
        overflow: "hidden"
      });
      this.$window.on("touchmove.noScroll", function(e) {
        e.preventDefault();
      });
      this.$main.on("click", this.toggleMenuOpen);
      this.$footer.on("click", this.toggleMenuOpen);
    } else {
      $("html, body").css({
        overflow: "scroll"
      });
      this.$window.off(".noScroll");
      this.$main.off("click", this.toggleMenuOpen);
      this.$footer.off("click", this.toggleMenuOpen);
    }
  };

  onScroll = () => {
    const prevScroll = this.scroll;
    const prevSticky = this.sticky;
    const prevVisible = this.visible;
    const height = this.$el.height();
    this.scroll = this.$window.scrollTop();
    const scrollDown = this.scroll > prevScroll;
    this.visible = !scrollDown;
    if (this.visible) {
      this.sticky = prevSticky;
    } else {
      this.sticky = this.scroll > height;
    }
    if (this.scroll === 0) {
      this.sticky = false;
      this.visible = false;
    }
    if (this.sticky === prevSticky) {
      // Do nothing
    } else if (this.sticky) {
      this.$el.addClass("sticky");
      this.$main.addClass("nav-sticky");
    } else {
      this.$el.removeClass("sticky");
      this.$main.removeClass("nav-sticky");
    }
    if (this.visible !== prevVisible) {
      this.$el.toggleClass("visible", this.visible);
    }
  };
}
